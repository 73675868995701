module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\Users\\anelson\\Documents\\firebase_site\\lordachoo.com-gitbook\\src\\templates\\docs.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"C:/Users/anelson/Documents/firebase_site/lordachoo.com-gitbook/node_modules/gatsby-remark-images","id":"57165b25-72e9-51ee-a893-e9e53c027cde","name":"gatsby-remark-images","version":"6.0.0","modulePath":"C:\\Users\\anelson\\Documents\\firebase_site\\lordachoo.com-gitbook\\node_modules\\gatsby-remark-images\\index.js","pluginOptions":{"plugins":[],"maxWidth":650,"sizeByPixelDensity":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"C:/Users/anelson/Documents/firebase_site/lordachoo.com-gitbook/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.0.0","modulePath":"C:\\Users\\anelson\\Documents\\firebase_site\\lordachoo.com-gitbook\\node_modules\\gatsby-remark-copy-linked-files\\index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\anelson\\Documents\\firebase_site\\lordachoo.com-gitbook","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"sizeByPixelDensity":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-RMEEEJVXW9","head":true,"anonymize":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Gitbook Starter","short_name":"GitbookStarter","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","crossOrigin":"use-credentials","icons":[{"src":"src/pwa-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"C:\\Users\\anelson\\Documents\\firebase_site\\lordachoo.com-gitbook\\src\\custom-sw-code.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
